import React, { useState } from 'react';
// import useLocalStorageState from 'use-local-storage-state';
import { useMediaQuery } from '@material-ui/core';

import { themeX } from '../styles/theme';
import { themeLight } from '../styles/themeLight';

const SettingsContext = React.createContext();

function SettingsProvider(props) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const [currentTheme, setCurrentTheme] = useState('light');
  const theme = themeLight;
  const toggleTheme = () => setCurrentTheme(() => 'light');

  return (
    <SettingsContext.Provider
      value={{
        currentTheme,
        toggleTheme,
        setCurrentTheme,
        prefersDarkMode,
        theme,
      }}
      {...props}
    />
  );
}

export { SettingsProvider, SettingsContext };
