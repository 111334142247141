import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { YMInitializer } from 'react-yandex-metrika';
import App from './App';
// import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import AppProviders from './context';

ReactDOM.render(
  <AppProviders>
    <YMInitializer accounts={[68112355]} options={{ webvisor: true }} version="2" />
    <BrowserRouter>
      <CssBaseline />
      <Route exact path="/" component={App} />
      {/* <Route exact path="/registration" component={Registration} /> */}
      <Route exact path="/forgot/password" component={ForgotPassword} />
      <Route exact path="/forgot/password-reset" component={PasswordReset} />
      <Route path="/apps" component={App} />
      <Route path="/colleagues" component={App} />
      <Route path="/settings" component={App} />
    </BrowserRouter>
  </AppProviders>,
  document.getElementById('root'),
);
