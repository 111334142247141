import React, { useEffect } from 'react';
import { getToken } from '../utils/auth-client';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [isAuth, setIsAuth] = React.useState(false);
  const [token, setToken] = React.useState('');

  useEffect(() => {
    const token = getToken();
    if (token) {
      setIsAuth(true);
      setToken(token);
    } else {
      setIsAuth(false);
      setToken('');
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        token,
        setToken,
      }}
      {...props}
    />
  );
}

export { AuthProvider, AuthContext };
