import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme()

const themeX = createMuiTheme({
    palette: {
        type: "dark"
    },
    overrides: {
	    MuiCssBaseline: {
	      '@global': {
		    '*::-webkit-scrollbar': {
		      width: '0.6em',
		      height: '0.6em'
		    },
		    '*::-webkit-scrollbar-track': {
		      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
					borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
		    },
		    '*::-webkit-scrollbar-thumb': {
		      backgroundColor: theme.palette.grey[600],
		      outline: '1px solid slategrey'
		    }
		  }
	    },
	 },
});

export {themeX}