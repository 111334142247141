import React, { useEffect, useContext } from 'react';

import { AuthContext } from './auth-context';
import { simpleCallRpc } from '../utils/api-client';

const UserContext = React.createContext();

function UserProvider(props) {
  const authContext = useContext(AuthContext);

  const [workspaces, setWorkspaces] = React.useState([]);
  const [selected_workspace, setSelectedWorkspace] = React.useState('');
  const [applications, setApplications] = React.useState([]);
  const [colleagues, setColleagues] = React.useState([]);
  const [selectedApplicationOrColleague, setSelectedApplicationOrColleague] = React.useState('nothing');
  const [selectedApplication, setSelectedApplication] = React.useState('');
  const [selectedColleague, setSelectedColleague] = React.useState(0);
  const [qwepToken, setQwepToken] = React.useState('');

  // useEffect(() => {
  //   if (authContext.isAuth && selected_workspace != '') {
  //     simpleCallRpc(authContext, 'get_qwep_token', {
  //       workspace_hash: selected_workspace,
  //     }, true)
  //       .then((result) => {
  //         // if(result.data.length > 0)
  //         // {
  //         //   setQwepToken(result.data[0].token)
  //         // }
  //       });
  //   }
  // },
  // [authContext.isAuth, selected_workspace]);

  useEffect(() => {
    if (selected_workspace !== '') {
      simpleCallRpc(authContext, 'get_user_applications', { workspace_hash: selected_workspace })
        .then((result) => setApplications(result.data));
    }
  },
  [selected_workspace]);

  return (
    <UserContext.Provider
      value={{
        workspaces,
        setWorkspaces,
        selected_workspace,
        setSelectedWorkspace,
        applications,
        setApplications,
        colleagues,
        setColleagues,
        selectedApplicationOrColleague,
        setSelectedApplicationOrColleague,
        selectedApplication,
        setSelectedApplication,
        selectedColleague,
        setSelectedColleague,
        qwepToken,
        setQwepToken,
      }}
      {...props}
    />
  );
}

export { UserProvider, UserContext };
