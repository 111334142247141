import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingBottom: theme.spacing(8),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
}));

const Logo = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <img onClick={() => history.push('/')} src="/logo-eberspaecher.png" className={classes.logo} alt="Логотип eberspaecher" />
  );
};

export default Logo;
