import React from 'react';
import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { SettingsProvider } from './settings-context';

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>
        <SettingsProvider>
          {children}
        </SettingsProvider>
      </UserProvider>
    </AuthProvider>
  );
}
export default AppProviders;
