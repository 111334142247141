async function simpleCallRpc(context, endpoint, body, appData){

  let link = null
  if (appData)
    link = '/api/data/rpc/'
  else
    link = '/api/rpc/'

  try {
    const response = await
          fetch(link+endpoint, {
              method: 'POST',
              headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'authorization': 'Bearer '+ context.token
              },
              body: JSON.stringify(body),
              json: true,
          })
    const status = await response.status
    if (status === 401)
    {
      context.setIsAuth(false)
      context.setToken('')
      if(appData)
        return {
          workspace_hash: body.workspace_hash,
          data: []
        }
      else
        return []
    }
    if (status === 204)
    {
      if(appData)
        return {
          workspace_hash: body.workspace_hash,
          data: []
        }
      else
        return []
    }
    const result = await response.json()
    return result
  } catch(error){
    console.error('Ошибка:', error)
    return []
  }
}

export {simpleCallRpc}
