import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from './context/auth-context';

const loadAuthenticatedApp = () => import('./authenticated-app');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

const useStyles = makeStyles(() => ({
  main: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function App() {
  const authContext = useContext(AuthContext);
  const classes = useStyles();

  return (
    <React.Suspense fallback={(
      <div className={classes.main}>
        <CircularProgress />
      </div>
    )}
    >
      {authContext.isAuth ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
}

export default App;
